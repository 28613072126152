import React, { useRef } from "react";
import { Button, IconArrow } from "../UI";
import styles from "./FeaturesSection.module.scss";
import cn from "classnames";
import { featuresConfig } from "./config";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const FeaturesSection = () => {
  const swiperRef = useRef(null);
  const timeoutRef = useRef(null);

  const swiperParams = {
    slidesPerView: "auto",
    centeredSlides: false,
    initialSlide: 0,
    spaceBetween: 16,
    slideActiveClass: "is-active",
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    navigation: {
      nextEl: ".js-feature-next",
      prevEl: ".js-feature-prev",
      disabledClass: "is-disabled",
    },
  };

  const handleSlideChange = (delay) => {
    const swiper = swiperRef.current.swiper;
    if (swiper.isEnd) {
      timeoutRef.current = setTimeout(() => {
        swiper.slideTo(0);
      }, delay);
    } else {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    featuresConfig?.length > 0 && (
      <div className={styles["features"]} id="features">
        <div className={cn("wrapper", styles["features__wrapper"])}>
          <h2 className={cn("main-title", styles["features__title"])}>
            Our <span>features</span>
          </h2>

          <Swiper
            className={cn(styles["features__slider"], "features__slider")}
            {...swiperParams}
            ref={swiperRef}
            onSlideChangeTransitionEnd={() => handleSlideChange(5000)}
          >
            {featuresConfig.map(({ title, text, button }, i) => {
              const slideNumber = i + 1;
              return (
                <SwiperSlide
                  className={styles["features__slide"]}
                  key={`review-${slideNumber}`}
                >
                  <div className={styles["features__slide-content"]}>
                    <img
                      className={styles["image"]}
                      srcSet={`/images/home/feature-${slideNumber}.png 1x, /images/home/feature-${slideNumber}@2x.png 2x`}
                      src={`/images/home/feature-${slideNumber}.png`}
                      width="400"
                      height="560"
                      alt={`Feature-${slideNumber}`}
                    />
                    <div className={styles["features__slide-bottom"]}>
                      <div>
                        <span className={styles["counter"]}>
                          {++i}/{featuresConfig.length}
                        </span>
                        <h3 className={styles["title"]}>{title}</h3>
                        <p className={styles["description"]}>{text}</p>
                      </div>
                      <Button
                        className={styles["button"]}
                        href={button.link}
                        rel="nofollow"
                        external={"true"}
                      >
                        {button.text}
                      </Button>
                      <NavButtons />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    )
  );
};

export default FeaturesSection;

const NavButtons = () => {
  return (
    <div className={cn(styles["features__navigation"], "swiper-navigation")}>
      <div
        className={cn(
          styles["features__navigation-btn"],
          "swiper-navigation-btn",
          "js-feature-prev",
        )}
      >
        <IconArrow
          className={cn(
            styles["features__navigation-icon"],
            "swiper-navigation-icon",
          )}
        />
      </div>
      <div
        className={cn(
          styles["features__navigation-btn"],
          "swiper-navigation-btn",
          "js-feature-next",
        )}
      >
        <IconArrow
          className={cn(
            styles["features__navigation-icon"],
            "swiper-navigation-icon",
            "next",
          )}
        />
      </div>
    </div>
  );
};
