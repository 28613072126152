import cn from "classnames";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Nav } from "../Nav";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 991px)");

  const handleNavOpen = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    if (isNavOpen && !isMobile) {
      setIsNavOpen(false);
    }
  }, [isNavOpen, isMobile]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const body = document.body;
      body.classList.toggle("disabled-scroll", isNavOpen);
    }
  }, [isNavOpen]);

  return (
    <header className={styles["header"]}>
      <div className={cn("wrapper", styles["header__wrapper"])}>
        <Link to="/" className={styles["header__logo"]}>
          <img src={"/images/logo.svg"} width="44" height="44" alt="Logo" />
        </Link>

        <Nav isNavOpen={isNavOpen} onNavClose={() => setIsNavOpen(false)} />

        <button
          className={cn(
            "hamburger",
            "hamburger--spin",
            styles["header__hamburger"],
            { "is-active": isNavOpen },
          )}
          type="button"
          aria-expanded={isNavOpen}
          aria-label={isNavOpen ? "closeTheMenu" : "openTheMenu"}
          onClick={handleNavOpen}
        >
          <span className={cn("hamburger-box")}>
            <span className={cn("hamburger-inner")}></span>
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;
