import React, { useRef } from "react";
import { IconArrow, IconGrate4, IconGrate5 } from "../UI";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { ParagraphWithReadMore } from "../UI/ParagraphWithReadMore";

import styles from "./ReviewsSection.module.scss";
import cn from "classnames";
import { reviewsConfig } from "./config";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const ReviewsSection = () => {
  const swiperRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const timeoutRef = useRef(null);

  const swiperParams = {
    slidesPerView: "auto",
    centeredSlides: true,
    initialSlide: 0,
    spaceBetween: 30,
    slideActiveClass: "is-active",
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    navigation: {
      nextEl: ".js-review-next",
      prevEl: ".js-review-prev",
      disabledClass: "is-disabled",
    },
  };

  const handleSlideChange = (delay) => {
    const swiper = swiperRef.current.swiper;
    if (swiper.isEnd) {
      timeoutRef.current = setTimeout(() => {
        swiper.slideTo(0);
      }, delay);
    } else {
      clearTimeout(timeoutRef.current);
    }
  };

  const transformName = (name) => name.toLowerCase().replace(/\s+/g, "-");

  return (
    <div className={styles["reviews"]}>
      <div className={"wrapper"}>
        <h2 className={"main-title"}>
          <span>Feedback</span> from parents
        </h2>

        <Swiper
          className={cn(styles["reviews__slider"], "reviews__slider")}
          {...swiperParams}
          ref={swiperRef}
          onSlideChangeTransitionEnd={() => handleSlideChange(5000)}
        >
          {reviewsConfig.map(({ name, date, grade, title, text }, i) => {
            return (
              <SwiperSlide
                className={styles["reviews__slide"]}
                key={`review-${i}`}
              >
                <div className={styles["top"]}>
                  <img
                    className={styles["image"]}
                    src={`/images/common/reviews/${transformName(name)}.png`}
                    width="72"
                    height="72"
                    alt={name}
                  />
                  <div>
                    <span className={styles["author"]}>{name}</span>
                    <span className={styles["date"]}>{date}</span>
                    {grade ? (
                      <IconGrate5 className={styles["icon"]} />
                    ) : (
                      <IconGrate4 className={styles["icon"]} />
                    )}
                  </div>
                </div>
                <h3 className={styles["title"]}>{title}</h3>
                {isMobile ? (
                  <ParagraphWithReadMore
                    className={styles["description"]}
                    text={text}
                    maxLength={200}
                  />
                ) : (
                  <p className={styles["description"]}>{text}</p>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={cn(styles["reviews__navigation"], "swiper-navigation")}>
          <div
            className={cn(
              styles["reviews__navigation-btn"],
              "swiper-navigation-btn",
              "js-review-prev",
            )}
          >
            <IconArrow
              className={cn(
                styles["reviews__navigation-icon"],
                "swiper-navigation-icon",
              )}
            />
          </div>
          <div
            className={cn(
              styles["reviews__navigation-btn"],
              "swiper-navigation-btn",
              "js-review-next",
            )}
          >
            <IconArrow
              className={cn(
                styles["reviews__navigation-icon"],
                "swiper-navigation-icon",
                "next",
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsSection;
