const navConfig = [
  {
    href: '/privacy',
    text: 'Privacy Policy',
  },
  {
    href: '/terms',
    text: 'Terms of Use',
  },
  {
    href: '/refund-policy',
    text: 'Refund Policy',
  },
  {
    href: '/compatibility',
    text: 'Compatibility Policy',
  }
];

export { navConfig };
