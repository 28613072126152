import cn from "classnames";
import styles from "./HeroSection.module.scss";
import { Button, IconAndroid, IconIphone } from "../UI";

const HeroSection = () => {
  return (
    <div className={styles["hero"]}>
      <div className={cn("wrapper", styles["hero__wrapper"])}>
        <div className={styles["hero__main-container"]}>
          <h1 className={cn(styles["hero__title"], "title")}>
            Ultimate <span>Safety App</span> for Mobile Devices
          </h1>
          <p className={styles["hero__description"]}>
            Get instant access to the child’s phone data and features
          </p>
          <Button
            className={styles["hero__button"]}
            href="#"
            rel="nofollow"
            external={"true"}
          >
            Try now
          </Button>
          <div className={styles["hero__available"]}>
            <span>Available on</span>
            <IconIphone />
            <IconAndroid className={styles["hero__android"]} />
          </div>
        </div>
        <picture className={styles["hero__picture"]}>
          <img
            className={styles["hero__image"]}
            srcSet={"/images/home/hero.png 1x, /images/home/hero@2x.png 2x"}
            src={"/images/home/hero.png"}
            width="620"
            height="621"
            alt="Hero"
          />
        </picture>
      </div>
    </div>
  );
};

export default HeroSection;
