import React from 'react';
import HeroSection from "../components/HeroSection";
import WhySection from "../components/WhySection";
import GetStartedSection from "../components/GetStartedSection";
import ReviewsSection from "../components/ReviewsSection";
import FeaturesSection from "../components/FeaturesSection";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Discovenger</title>
        <meta name="description" content="Ultimate parental control app" />
      </Helmet>
      <HeroSection/>
      <FeaturesSection />
      <WhySection />
      <ReviewsSection />
      <GetStartedSection />
    </>
  );
};

export default Home;