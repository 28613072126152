const reviewsConfig = [
  {
    name: "Olivia Smith",
    date: "June 1, 2023",
    grade: true,
    title: "It is super helpful!",
    text: "Just wanted to say thank you to the Discovenger app! I now I can be really patient about my children!",
  },
  {
    name: "James Davis",
    date: "March 7, 2023",
    grade: false,
    title: "It's a great app",
    text: "If you're a parent you should definitely try this app it’s the best app that works stealthily on the target device. I can be at peace knowing what's my children upto in their social networks.",
  },
  {
    name: "Fatoumata D",
    date: "January 20, 2023",
    grade: true,
    title: "So far so good!",
    text: "Works for what I need it for with an Android at least. Support agent was very helpful with the installation and everything seems to be working fine! So far, I would recommend the app.",
  },
  {
    name: "Kim Yoo-jung",
    date: "April 27, 2023",
    grade: false,
    title: "Helps me to stay alert at all times",
    text: "Now I always know what my kids are up to when I’m away. Makes parenting so much easier.",
  },
  {
    name: "Lee Jong-suk",
    date: "January 20, 2023",
    grade: true,
    title: "Takes monitoring to a whole new level",
    text: "Even when I can’t check their phone activity myself, I can be sure that I’ll get alerted if anything happens.",
  },
  {
    name: "Chinedu O",
    date: "January 20, 2023",
    grade: true,
    title: "Trustworthy monitoring solution",
    text: "Keeps me updated on everything that’s happening on the device. Superb GPS tracking and keylogging.",
  },
];

export { reviewsConfig };
