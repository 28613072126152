import cn from "classnames";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { scrollToElement } from "../../utils/scrollToElement";
import {useLocation} from "react-router-dom";

const Policy = ({ pageDate }) => {
  const contentRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const location = useLocation();
  const isCompatibility = location.pathname === '/compatibility';

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains("contents__link")) {
        event.preventDefault();
        const id = event.target.getAttribute("href").replace("#", "");
        scrollToElement(id, isMobile, 10)(event);
      }
    };

    const currentRef = contentRef.current;
    currentRef.addEventListener("click", handleClick);
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("click", handleClick);
      }
    };
  }, [isMobile]);

  return (
    <section className="policy-section">
      <div className={cn("policy-section__wrapper", "wrapper")}>
        <h1
          className={cn("policy-section__title", {'policy-section__title-compatibility': isCompatibility} )}
          dangerouslySetInnerHTML={{
            __html: pageDate.title,
          }}
        />

        <div
          className="policy-section__text"
          ref={contentRef}
          dangerouslySetInnerHTML={{
            __html: pageDate.text,
          }}
        />
      </div>
    </section>
  );
};

export default Policy;
