const featuresConfig = [
  {
    title: 'Surroundings Recording',
    text: 'Just because you can’t be there doesn’t mean you can’t find out what’s happening. With the Surroundings Recording, it’s easy to listen in on them.',
    button: {
      link: '#',
      text: 'Try now'
    }
  },
  {
    title: 'Live Camera',
    text: 'Get access to your child\'s camera anytime. Make sure your loved ones are in the right place at the right time.',
    button: {
      link: '#',
      text: 'Try now'
    }
  },
  {
    title: 'Messengers',
    text: 'With the text monitoring app, you\'ll stay one step ahead of Instagram scam messages, bullying comments, and other online dangers.',
    button: {
      link: '#',
      text: 'Try now'
    }
  },
  {
    title: 'Installed Apps',
    text: 'Find out if they’ve got dangerous apps installed on their phone. Then take action if they’re using apps you don’t approve of.',
    button: {
      link: '#',
      text: 'Try now'
    }
  },
  {
    title: 'Contacts',
    text: 'Now it’s easy to find out who their friends are. And if they add someone new to their Contacts, you’ll know about it.',
    button: {
      link: '#',
      text: 'Try now'
    }
  },
  {
    title: 'Panic Button',
    text: 'If they’re in trouble, they can let you know with Discovenger. One tap and the Panic Button sends an alert and their current location to your phone.',
    button: {
      link: '#',
      text: 'Try now'
    }
  },
  {
    title: 'Location Alert',
    text: 'No need to constantly check their location on the map. When they enter or leave a specific location, Discovenger will let you know.',
    button: {
      link: '#',
      text: 'Try now'
    }
  },
  {
    title: 'Live Location',
    text: 'Are they roaming around a dangerous neighborhood? With Discovenger, you have the power to see their location and the history of visited places.',
    button: {
      link: '#',
      text: 'Try now'
    }
  },
];

export { featuresConfig };
