import cn from "classnames";
import styles from "./GetStartedSection.module.scss";
import { Button, IconCheck, IconDownload, IconEmail } from "../UI";
import React from "react";

const GetStartedSection = () => {
  return (
    <div className={styles["get-started"]}>
      <div className={"wrapper"}>
        <h2 className={cn(styles["get-started__title"], "main-title")}>
          Get Started <br /> in <span>3 Easy Steps</span>
        </h2>

        <div className={styles["get-started__items"]}>
          <div className={styles["get-started__item"]}>
            <div className={styles["icon_wrap"]}>
              <IconEmail className={styles["icon"]} />
            </div>
            <div className={styles["container"]}>
              <span className={styles["step"]}>Step 1</span>
              <h3 className={styles["title"]}>
                Register for a Discovenger account just with your email
              </h3>
              <p className={styles["description"]}>
                Sign Up for a 0.99$ 1 day trial
              </p>
            </div>
          </div>

          <div className={styles["get-started__item"]}>
            <div className={styles["icon_wrap"]}>
              <IconDownload className={styles["icon"]} />
            </div>
            <div className={styles["container"]}>
              <span className={styles["step"]}>Step 2</span>
              <h3 className={styles["title"]}>Install App</h3>
              <p className={styles["description"]}>
                Follow the setup instructions emailed to you. <br /> It takes a
                few minutes
              </p>
            </div>
          </div>

          <div className={styles["get-started__item"]}>
            <div className={styles["icon_wrap"]}>
              <IconCheck className={styles["icon"]} />
            </div>
            <div className={styles["container"]}>
              <span className={styles["step"]}>Step 3</span>
              <h3 className={styles["title"]}>
                Start monitoring and rest easy
              </h3>
              <p className={styles["description"]}>You're ready to monitor!</p>
            </div>
          </div>
        </div>

        <Button
          className={styles["get-started__button"]}
          href="#"
          rel="nofollow"
          external={"true"}
        >
          Try now
        </Button>
      </div>
    </div>
  );
};

export default GetStartedSection;
