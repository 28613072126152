import { useEffect, useRef, useState } from "react";
import styles from "./ParagraphWithReadMore.module.scss";
import cn from "classnames";

export const ParagraphWithReadMore = ({ text, maxLength, className }) => {
  const paragraphRef = useRef(null);
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  const [activeItem, setActiveItem] = useState(false);

  useEffect(() => {
    const characterCount = text.length;

    if (characterCount > maxLength) {
      setShowReadMoreButton(true);
    }
  }, [text, maxLength]);

  function truncateParagraph(text) {
    if (text.length <= maxLength) {
      return text;
    }
    const truncatedText = text.substr(0, maxLength - 12);
    return (
      truncatedText.substr(
        0,
        Math.min(truncatedText.length, truncatedText.lastIndexOf(" ")),
      ) + "..."
    );
  }

  return (
    <>
      <div
        className={cn(className, styles["paragraphWithReadMore"], {
          [styles["text-full"]]: activeItem,
        })}
        ref={paragraphRef}
      >
        {!activeItem && showReadMoreButton ? (
          <span className={styles["paragraphWithReadMore__short"]}>
            {truncateParagraph(text)}
          </span>
        ) : (
          <span className={styles["paragraphWithReadMore__long"]}>{text}</span>
        )}

        {showReadMoreButton ? (
          activeItem ? (
            <button
              className={styles["paragraphWithReadMore__button"]}
              onClick={() => setActiveItem(!activeItem)}
            >
              Read less
            </button>
          ) : (
            <button
              className={styles["paragraphWithReadMore__button"]}
              onClick={() => setActiveItem(!activeItem)}
            >
              Read more
            </button>
          )
        ) : null}
      </div>
    </>
  );
};
