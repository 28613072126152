import styles from "./WhySection.module.scss";
import { Button, IconTrustpilot } from "../UI";
import React from "react";

const WhySection = () => {
  return (
    <div className={styles["why"]}>
      <div className={"wrapper"}>
        <h2 className={"main-title"}>
          Why <span>Discovenger</span>
        </h2>
        <div className={styles["why__items"]}>
          <div className={styles["why__item"]}>
            <img
              className={styles["image"]}
              srcSet={"/images/home/why-1.jpg 1x, /images/home/why-1@2x.jpg 2x"}
              src={"/images/home/why-1.jpg"}
              width="108"
              height="120"
              alt="why-1"
            />
            <h3 className={styles["title"]}>
              87% of our customers <br /> totally satisfied
            </h3>
            <p className={styles["description"]}>Over 6M downloads worldwide</p>
            <IconTrustpilot className={styles["image-bottom"]} />
          </div>

          <div className={styles["why__item"]}>
            <img
              className={styles["image"]}
              srcSet={"/images/home/why-2.jpg 1x, /images/home/why-2@2x.jpg 2x"}
              src={"/images/home/why-2.jpg"}
              width="108"
              height="120"
              alt="why-2"
            />
            <h3 className={styles["title"]}>Safe & secure</h3>
            <p className={styles["description"]}>
              We use encryption to transmit the information. <br /> That means
              only you can see it.
            </p>
          </div>

          <div className={styles["why__item"]}>
            <img
              className={styles["image"]}
              srcSet={"/images/home/why-3.jpg 1x, /images/home/why-3@2x.jpg 2x"}
              src={"/images/home/why-3.jpg"}
              width="108"
              height="120"
              alt="why-2"
            />
            <h3 className={styles["title"]}>A free trial available!</h3>
            <p className={styles["description"]}>
              Get a taste of our features with a 1-day trial
            </p>
            <Button
              className={styles["button"]}
              href="#"
              rel="nofollow"
              external={"true"}
            >
              Try now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySection;
